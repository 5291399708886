.strikethrough-able-grid td {
  position: relative;
}

.strikethrough-able-grid tr td.strikethrough:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid #111;
  width: 100%;
}

.strikethrough-able-grid tr td:after {
  //content: "\00B7";
  font-size: 1px;
}


@use '@angular/material' as mat;
@use "sass:map";

@include mat.core();

/// ANGULAR MATERIALS THEME ////////

// https://www.youtube.com/watch?v=M7q2Ty-y2zQ&t=596s
// https://material.angular.io/guide/theming
// https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
// https://material.angular.io/guide/theming-your-components

$custom-primary-palette: (
  50: #e0f6f8,
  100: #b3e8ed,
  200: #85d9e1,
  300: #5ac9d5,
  400: var(--highlight-fg-color),
  500: var(--highlight-fg-color),
  600: #34a3b2,
  700: #2f8f99,
  800: #2b7b82,
  900: #215859,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff
  )
);

$custom-primary: mat.m2-define-palette($custom-primary-palette);
$custom-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$custom-warn: mat.m2-define-palette(mat.$m2-red-palette);


$custom-theme: mat.m2-define-light-theme(
    (
      color: (primary: $custom-primary,
        accent: $custom-accent,
        warn: $custom-warn,
      )
    )
);

$color-map: map.get($custom-theme, 'color');

// background
$background-color-map: map.get($color-map, 'background');

$background-color-map: map.merge($background-color-map, ('background': var(--application-bg-color)));
//$background-color-map: map.merge($background-color-map, ('status-bar': red));
//$background-color-map: map.merge($background-color-map, ('app-bar': #0022ff));
//$background-color-map: map.merge($background-color-map, ('hover': rgba(255, 255, 165, 0.54)));
//$background-color-map: map.merge($background-color-map, ('card': rgba(97, 151, 255, 0.76)));
//$background-color-map: map.merge($background-color-map, ('dialog': #2bb3e5));
//$background-color-map: map.merge($background-color-map, ('raised-button': #27933e));
//$background-color-map: map.merge($background-color-map, ('selected-button': #fff665));
//$background-color-map: map.merge($background-color-map, ('selected-disabled-button': #e5d66f));
//$background-color-map: map.merge($background-color-map, ('disabled-button': rgba(255, 0, 0, 0.49)));
//$background-color-map: map.merge($background-color-map, ('focused-button': #8a7b7b));
//$background-color-map: map.merge($background-color-map, ('disabled-button-toggle': #bdbcbc));
//$background-color-map: map.merge($background-color-map, ('unselected-chip': #00dd8e));
//$background-color-map: map.merge($background-color-map, ('disabled-list-option': #37ff00));
//$background-color-map: map.merge($background-color-map, ('tooltip': #d0ff00));

$modified-background-color-map: $background-color-map;

$modified-color-map: map.merge($color-map, ('background': $modified-background-color-map));

// foreground
$foreground-color-map: map-get($color-map, foreground);
$modified-foreground-color-map: map_merge($foreground-color-map, (text: var(--application-fg-color)));

$modified-color-map: map.merge($modified-color-map, ('foreground': $modified-foreground-color-map));


$custom-theme: map.merge($custom-theme, ('color': $modified-color-map));

@include mat.all-component-themes($custom-theme);

.the-dense-zone-3 {
  @include mat.all-component-densities(-3)
}

.the-dense-zone-4 {
  //@include mat.select-density(-5);
  //@include mat.button-density(-5);
  //@include mat.input-density(-5);
  //@include mat.form-field-density(-5);
  //@include mat.private-form-field-density(-5);
  //@include mat.option-density(-5);
  @include mat.all-component-densities(-4)
}

.the-dense-zone-4-search {
  @include mat.all-component-densities(-4);
  --mat-form-field-container-height: 34px;
  --mat-form-field-filled-with-label-container-padding-top: 5px;
}

.the-dense-zone-5 {
  @include mat.all-component-densities(-5)
}

// Breakpoints
$breakpoint-mobile: 479.9px;
$breakpoint-tablet-small: 767.9px;
$breakpoint-tablet: 1023.9px;
$breakpoint-tablet-big: 1279.9px;
$breakpoint-desktop: 1439.9px;
$breakpoint-desktop-big: 1679.9px;

@mixin media($type) {
  @if $type == 'mobile' {
    @media only screen and (max-width: $breakpoint-mobile) {
      @content;
    }
  } @else if $type == 'tablet-small' {
    @media only screen and (max-width: $breakpoint-tablet-small) {
      @content;
    }
  } @else if $type == 'tablet' {
    @media only screen and (max-width: $breakpoint-tablet) {
      @content;
    }
  } @else if $type == 'tablet-big' {
    @media only screen and (max-width: $breakpoint-tablet-big) {
      @content;
    }
  } @else if $type == 'desktop' {
    @media only screen and (max-width: $breakpoint-desktop) {
      @content;
    }
  }
}

@mixin media-up($type) {
  @if $type == 'tablet' {
    @media only screen and (min-width: 768px) {
      @content;
    }
  } @else if $type == 'desktop' {
    @media only screen and (min-width: 1280px) {
      @content;
    }
  }
}

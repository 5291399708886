.due-date-status_none {
  background-color: #a3def4 !important;
}

.due-date-status_past {
  background-color: rgb(250, 208, 208) !important;
}

.due-date-status_today {
  background-color: #ff903e !important;
}

.due-date-status_soon {
  background-color: #fff18e !important;
}

.due-date-status_future {
  background-color: #abf1b4 !important;
}

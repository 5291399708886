@import "fonts";

// Colors
$test-blue: #0000FF;

// Fonts
$dinot: DINOT, Helvetica, Arial, sans-serif;
$d-black: D-Black, Helvetica, Arial, sans-serif;

$font-size-text-small: 14px;
$font-size-text: 18px;
$font-size-text-large: 24px;

:root {
  --application-bg-color: #eeeeee;
  --grid-odd-rows-color: #e8e8e8;
  --application-fg-color: #555555;

  --burger-menu-bg-color: #404040;
  --burger-menu-fg-color: #eeeeee;

  --header-bg-color: #404040;
  --header-fg-color: #eeeeee;

  --icon-fg-color: #5f5f5f; // rgb(95 95 95);
  --highlight-fg-color: rgb(68, 190, 204);
  --highlight-bg-color: rgba(68, 190, 204, 0.05);

  --explorer-grid-height: 78vh;

  --highlight-cursor: #fff665;
}

@font-face {
  font-family: 'DINOT';
  src: url('../fonts/dinot/DINOT.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'D-Black';
  src: url('../fonts/dinot/DINOT-Black.otf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'D-Bold';
  src: url('../fonts/dinot/DINOT-Bold.otf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'D-Light';
  src: url('../fonts/dinot/DINOT-Light.otf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'D-Medium';
  src: url('../fonts/dinot/DINOT-Medium.otf');
  font-style: normal;
  font-weight: normal;
}

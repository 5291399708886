.p-column-filter-add-button {
  color: #555555;
}

.p-column-filter-buttonbar .p-button-sm {
  color: #42becc;
}

.full-height .p-datatable-wrapper {
  min-height: var(--explorer-grid-height);
}

.message-list {
  margin: 0;
  list-style-type: none;
  padding: 0
}

@import "variables";
@import "mixins";

.app-wrapper {
  height: 100%;
  width: 100%;
  font-family: $dinot;
  font-size: $font-size-text;
  overflow-x: hidden;
  margin: 0 auto;
  color: $test-blue;

  @include media('tablet-big') {
    width: 70%;
  }
}


*:not(mat-icon, .p-treenode-icon) {
  font-family: $dinot;
}

.row {
  margin-top: 0.7rem;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
}

.grid-shadow {
  box-shadow: -3px -3px 10px #8888;
}

.p-highlight, .p-highlight .p-treenode-icon, .p-highlight button {
  color: var(--highlight-fg-color) !important;
  background: var(--highlight-bg-color);
}

.p-element:not(.p-icon-wrapper, .p-toast-icon-close), .p-component, .p-dummy-element {
  background-color: var(--application-bg-color);
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: var(--grid-odd-rows-color);
}

.img-defaultphoto-icon{
  width: 35px;
  height: 35px;
  object-fit: contain;
  border:1px solid lightgray;
  border-radius: 35px;
  background: white;
  display: none;
  vertical-align: top;
  transition: transform .2s; /* Animation */
}

.img-defaultphoto-icon:hover {
  transform: scale(1.25); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  z-index:10;
}

.iconw70xh70{
  width: 70px !important;
  height: 70px  !important;
}

button:not(.mat-primary) .mat-icon {
  color: var(--icon-fg-color);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 0;
}

.p-tree .p-tree-container .p-treenode {
  padding: 0;
}

.p-treenode-children {
  padding-left: 1.8rem;
}

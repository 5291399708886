/* You can add global styles to this file, and also import other style files */
@import "variables";
@import "mixins";
@import "boostrap";
@import "primeng";
@import "angular-material";
@import "p-tree";
@import "p-table";
@import "quick-grid";
@import "strikethrough-able-grid";
@import "due-date";
@import "layout";
@import "highlight-cursor";
